







































































































































































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import VueCountryCode from "vue-country-code";
import PageTitle from "@/components/General/PageTitle.vue";
import { Navigation } from "@/mixins/navigation";
import { StyleCheck } from "@/mixins/style-check";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";
import { User } from "@/models/user.interface";
import info from "@/utils/info";
import Loader from "@/components/General/Loader.vue";
import LanguagePicker from "@/components/Input/LanguagePicker.vue";
import AliasChecker from "@/components/Input/AliasChecker.vue";
import { PenaltyUser } from "@/models/penalty.interface";
import PenaltyCard from "@/components/Layout/Common/PenaltyCard.vue";
import { Status } from "@/models/status.interface";
import { Socket } from "vue-socket.io-extended";

@Component({
  components: {
    VueCountryCode,
    PageTitle,
    Loader,
    LanguagePicker,
    AliasChecker,
    PenaltyCard,
  },
})
export default class Profile extends mixins(
  Navigation,
  FormValidations,
  StyleCheck
) {
  closeSessionDialog = false;
  closeSession: any = null;
  loadingForDialog = false;

  @Socket("session:updated")
  private async sessionUpdatedHandler(payload: any) {
    if (payload.user_id == this.user.id) {
      await this.getUserSessions();
    }
  }

  @Socket("penalty:update")
  private async penaltyUpdateHandler(payload: any) {
    if (payload.user_id == this.user.id) {
      this.getUserInfo().then(() => {
        this.getUserPenalties();
      });
    }
  }

  private openCloseSession(session: any) {
    this.closeSession = session;
    this.closeSessionDialog = true;
  }

  private hideSessionDialog() {
    this.closeSession = null;
    this.closeSessionDialog = false;
  }

  private async logOut() {
    await this.$store.dispatch("authentication/logOut").then(() => {
      this.navigate("/home");
    });
  }

  private async destroySession() {
    this.loadingForDialog = true;
    await this.$store
      .dispatch("users/removeUserSession", this.closeSession.session)
      .then(async () => {
        if (this.closeSession.current) {
          await this.logOut();
        } else {
          const Success: Notification = {
            message: this.$t("Views.np-7", {
              id: this.closeSession.session,
            }) as string,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };
          this.$store.dispatch("notifications/showNotification", Success);
          await this.getUserSessions();
          this.hideSessionDialog();
        }
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.np-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loadingForDialog = false;
  }

  $refs!: {
    profileForm: HTMLFormElement;
  };
  phoneCountry: { dialCode: string; iso2: string } = { dialCode: "", iso2: "" };
  formatedPhone = "";
  valid = false;
  loader = false;
  loading = false;
  cancelLoading = false;
  update = false;
  phoneiso2: string | undefined = "";
  userValidations = {
    alias: false,
  };
  user: User = {
    id: "",
    identification: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    address: {
      primary_line: "",
      secondary_line: "",
      city: "",
      state: "",
      country: "",
      zip_code: undefined,
    },
    language: undefined,
    alias: "",
  };
  userStatus?: Status;

  private async created() {
    await this.getUserInfo().then(async () => {
      await this.getUserPenalties();
    });
    await this.getUserSessions();
  }

  get penalties(): PenaltyUser[] {
    const pens = this.$store.getters["penalties/getPenalties"];
    return pens?.penalties || [];
  }
  get current_penalties(): PenaltyUser[] {
    return this.penalties.filter((pen) =>
      [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.FINISHED].includes(
        pen.status?.name
      )
    );
  }

  private async getUserPenalties() {
    await this.$store
      .dispatch("penalties/getClientPenalties", {
        client: this.user.id,
        page: 1,
        size: 1,
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Profile.penalties.fetchError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }
  private async getUserSessions() {
    await this.$store.dispatch("users/getUserSessions").catch(() => {
      const Error: Notification = {
        message: this.$tc("Views.np-e2"),
        timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
        top: true,
        bottom: false,
        left: false,
        right: false,
        currentPath: this.$route.fullPath,
        error: true,
      };

      this.$store.dispatch("notifications/showNotification", Error);
    });
  }

  private get userSessions(): string {
    return this.$store.getters["users/getUserSessions"];
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }
  checkPenalties() {
    this.$router.push({
      query: { redirection: "/profile", header: "t" },
      path: `/user/penalties/1`,
    });
  }
  private async changePassword() {
    if (this.context == this.$constants.CONTEXT.FRONTOFFICE) {
      this.$router.push({
        query: { redirection: "/profile", header: "t" },
        path: `/user/change-password/${info.getToken()}`,
      });
    } else {
      this.$router.push({
        query: { redirection: "/staff/profile", header: "t" },
        path: `/staff/change-password/${info.getToken()}`,
      });
    }
  }
  private async changeEmail() {
    if (this.context == this.$constants.CONTEXT.FRONTOFFICE) {
      this.$router.push({
        query: { redirection: "/profile", header: "t" },
        path: `/user/change-email/${info.getToken()}`,
      });
    } else {
      this.$router.push({
        query: { redirection: "/staff/profile", header: "t" },
        path: `/staff/change-email/${info.getToken()}`,
      });
    }
  }
  private selectedCountry(country: any): void {
    this.phoneCountry = country;
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      (this.user.phone == null ? "" : this.user.phone) +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }

  private async getUserInfo() {
    this.cancelLoading = true;
    this.loader = true;
    let user = await this.$store.getters["authentication/getUser"];
    await this.$store
      .dispatch("authentication/getUser", {
        id: user.id,
      })
      .then((user: User) => {
        this.phoneiso2 = user.phone?.split(" ")[2].split("(")[1].split(")")[0];
        this.user = {
          id: user.id,
          identification: user.identification,
          first_name: user.first_name,
          second_name: user.second_name ? user.second_name : "",
          last_name: user.last_name,
          second_last_name: user.second_last_name ? user.second_last_name : "",
          phone: user.phone?.split(" ")[1],
          alias: user.alias,
          email: user.email,
          address: user.address ? user.address : this.user.address,
          language: user.language || "es",
          penalty_grade: user.penalty_grade,
        };
        this.userStatus = user.status;
        this.update = false;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Profile.fetchError.info"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.cancelLoading = false;
        this.loader = false;
        window.scrollTo(0, 0);
      });
  }

  @Watch("language")
  resetValidations() {
    this.resetFormValidations([this.$refs.profileForm]);
  }

  @Watch("user.phone")
  setUserPhone(phone: string): void {
    const clearPhone = phone != null ? phone : "";
    this.formatedPhone =
      "+" +
      this.phoneCountry.dialCode +
      " " +
      clearPhone +
      " (" +
      this.phoneCountry.iso2 +
      ")";
  }

  private async submit() {
    this.loading = true;
    if (this.$refs.profileForm.validate() && !this.userValidations.alias) {
      let user = { ...this.user };
      user.phone = this.formatedPhone;
      await this.$store
        .dispatch("authentication/setUser", {
          id: user.id,
          user: user,
        })
        .then(() => {
          const Success: Notification = {
            message: this.$tc("Profile.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };
          this.$store.dispatch("users/getUserById", user.id);
          this.$store.dispatch("authentication/updateUserInfo", user);
          this.$store.dispatch("notifications/showNotification", Success);
          this.update = false;
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Profile.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
          window.scrollTo(0, 0);
        });
    } else {
      this.loading = false;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }

  private get alertToCompleteProfile(): boolean {
    if (
      this.user.address?.primary_line == "" ||
      this.user.address?.primary_line == null ||
      this.user.address?.city == "" ||
      this.user.address?.city == null ||
      this.user.address?.country == "" ||
      this.user.address?.country == null ||
      this.user.address?.state == "" ||
      this.user.address?.state == null
    ) {
      return true;
    } else {
      return false;
    }
  }
}
